@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
}

#root {
  height: 100vh; /* Set height to 100% of the viewport height */
}

.gm-style-iw-c {
  z-index: -9999 !important;
}